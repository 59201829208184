<template>
  <div class="close-place" :class="{ 'close-place--active': active }">
    <div>
      <img
        :src="require(`@/assets/images/second/${placeData.img}`)"
        :alt="`фото ${placeData.title}`"
        class="close-place__img"
        width="60"
        height="60"
      />
    </div>
    <div>
      <span class="close-place__title">{{ placeData.title }}</span>
      <span class="close-place__time"
        ><Icons icon-name="car" /> {{ placeData.time }}
      </span>
    </div>
  </div>
</template>

<script>
import Icons from '@/components/atoms/Icons/index.vue'

export default {
  name: 'ClosePlace',
  components: { Icons },
  props: {
    placeData: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
    isShownModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hover: false,
      isShowModal: false,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.close-place {
  min-width: fit-content;
  position: relative;
  justify-content: space-between;
  height: 144px;
  z-index: 5;
  padding: 0 0 15px 0;
  cursor: pointer;
  @include flex-column();

  @include tablet-portrait {
    min-width: auto;
    max-width: 120px;
  }

  @include mobile {
    min-width: fit-content;
  }

  &--active {
    border-bottom: 2px solid $c-main-green;

    .close-place__title {
      @include font-styles(18px, 1.4, 700, $c-main-green);
    }
  }

  &__title {
    @include font-styles(18px, 1.4, 700, $c-dark, 0, $font-third);
    padding-bottom: 5px;
  }
  &__time {
    gap: 7px;
    align-items: center;
    @include font-styles(15px, 1.4, 400, $c-gray);

    @include flex-row();
  }
}
</style>
