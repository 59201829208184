<template>
  <div class="second">
    <div class="container">
      <div class="second-top">
        <section class="second-top__left wow" data-wow-offset="200">
          <h2 class="second-top__left-title">
            Ваше вложение<br />
            в комфортную жизнь
          </h2>
          <p class="second-top__left-text--strong">
            Находясь на территории делового административного центра, данный
            объект имеет развитую инфраструктуру
          </p>
          <p class="second-top__left-text">
            В пешей доступности для жильцов находятся магазины разной
            направленности, аптеки, школы, детские сады, культурные и досуговые
            центры, а также ТД «Metro», ТРЦ «Diamond Plaza» и строящаяся большая
            главная мечеть
          </p>
        </section>
        <div class="second-top__right wow" data-wow-offset="200">
          <iframe
            v-if="openYoutube"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/1tZlVbfN6QY?autoplay=1&mute=1&enablejsapi=1"
            title="Творчество, Свобода, Созидание | Имиджевый ролик"
            frameborder="0"
            allowfullscreen
          ></iframe>
          <img
            v-else
            @click="openYoutube = true"
            width="100%"
            height="100%"
            src="@/assets/images/second/youtube.png"
            alt="картинка
          youtube превью"
          />
        </div>
      </div>
      <div class="second-bottom wow" data-wow-offset="50">
        <span class="second-bottom__title">Места поблизости</span>
        <div class="second-bottom-list">
          <CLosePlace
            v-for="place in placesData"
            :key="place.id"
            :place-data="place"
            :active="activePlace === place.id"
            @click.native="openPlaceModal(place)"
          />
        </div>
        <PlaceModal
          v-if="isShowModal"
          :place-data="currentPlace"
          :class="{ 'close-anim': showAnim }"
          @close="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CLosePlace from '@/components/molecules/Cards/ClosePlace/index.vue'
import PlaceModal from '@/components/atoms/Modals/PlaceModal.vue'
import { WOW } from 'wowjs'

export default {
  name: 'SecondSection',
  components: { CLosePlace, PlaceModal },
  data() {
    return {
      hover: false,
      isShowModal: false,
      openYoutube: false,
      showAnim: false,
      currentPlace: {},
      activePlace: -1,
      placesData: [
        {
          id: 0,
          title: 'Diamond Plaza',
          subtitle:
            'В ТРЦ DIAMOND PLAZA вы сможете весело и интересно провести выходной с семьей. Для это там есть все необходимое: кинотеатры, детские развлекательные центры, бутики для шопинг-терапии.',
          img: 'diamond.webp',
          bigImg: 'diamond.webp',
          time: '5 минут',
        },
        {
          id: 1,
          title: 'Библиотека',
          subtitle:
            'Любители спокойствия и умиротворения могут расположиться в библиотеке, где смогут найти необходимую литературу, продуктивно выполнять работу используя хороший интернет, что сегодня особенно актуально.',
          img: 'library.webp',
          bigImg: 'library.webp',
          time: '3 минуты',
        },
        {
          id: 2,
          title: 'Акимат',
          subtitle:
            'Вблизи дома расположен аппарат акима города Шымкент, двери которого всегда открыты для граждан. Каждый житель города может обратиться со своими вопросами и предложениями, его всегда готовы принять и выслушать.',
          img: 'akimat.webp',
          bigImg: 'akimat.webp',
          time: '5 минут',
        },
        {
          id: 3,
          title: 'ТД Metro',
          subtitle:
            'ТД Метро уже давно известен своим огромным ассортиментом различных товаров, поэтому его близкое расположение очень выгодно для жителей нашего района, так как все необходимое всегда в шаговой доступности.',
          img: 'metro.webp',
          bigImg: 'metro.webp',
          time: '5 минут',
        },
        {
          id: 4,
          title: 'Школы',
          subtitle:
            'Дорогие родители, вы можете отложить беспокойство за свое чадо, ведь в районе нашего ЖК расположены школы, в том числе и одна из лучших в стране - Назарбаев интеллектуальная школа. ',
          img: 'school.webp',
          bigImg: 'school.webp',
          time: '15 минут',
        },
        {
          id: 5,
          title: 'Туркестан сарайы',
          subtitle:
            'Культурный отдых всегда в шаговой доступности от вас, ведь рядом с вами расположен современный и крупномасштабный концертный зал «Туркестан», который вмещает в себя около 1500 человек.',
          img: 'palace.webp',
          bigImg: 'palace.webp',
          time: '5 минут',
        },
      ],
    }
  },
  mounted() {
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
    })
    wow.init()
  },
  watch: {
    isShowModal: {
      handler(val) {
        this.$store.commit('Modals/updateStatus', val)
      },
    },
  },
  methods: {
    openPlaceModal(place) {
      this.activePlace = place.id
      this.currentPlace = place
      this.isShowModal = true
    },
    close() {
      this.showAnim = true
      setTimeout(() => {
        this.showAnim = false
        this.isShowModal = !this.isShowModal
      }, 500)
    },
  },
}
</script>

<style lang="scss" scoped>
.second {
  position: relative;
  @include flex-column();

  @include mobile {
    padding: 0;
  }

  &-top {
    gap: 60px;
    @include flex-row();

    @include laptop {
      flex-direction: column;
      gap: 30px;
    }

    // @include tablet-portrait() {
    //   gap: 30px;
    // }

    @include mobile {
      gap: 20px;
      // flex-direction: column;
    }

    &__left {
      max-width: 600px;
      animation: manifestation1 0.8s ease-in;

      @include tablet-portrait() {
        max-width: 500px;
      }
      &-title {
        padding-bottom: 30px;
        @include font-styles(48px, 58px, 700, $c-dark, 0, $font-third);

        @include mobile {
          padding-bottom: 14px;
          @include font-styles(28px, 34px, 700, $c-dark, 0, $font-third);
        }
      }
      &-text {
        @include font-styles(18px, 24px, 400, $c-gray, 0, $font-second);

        @include mobile {
          text-align: left;
          @include font-styles(14px, 20px, 400, $c-gray, 0, $font-second);
        }
      }
      &-text--strong {
        max-width: 580px;
        padding-bottom: 30px;
        @include font-styles(22px, 30px, 700, $c-dark);

        @include mobile {
          text-align: left;
          padding-bottom: 14px;
          @include font-styles(16px, 20px, 700, $c-dark);
        }
      }
    }
    &__right {
      border-radius: 120px 40px 40px 40px;
      overflow: hidden;
      max-width: 614px;
      width: 100%;
      min-width: 610px;
      max-height: 370px;
      animation: manifestationOpacity 1.2s linear;
      cursor: pointer;

      @include mobile {
        width: 100%;
        min-width: auto;
        height: 204px;
        border-radius: 60px 20px 20px 20px;
      }
    }
  }
  &-bottom {
    animation: manifestation1 1s ease-in;
    position: relative;
    @include mobile {
      position: static;
    }
    &__title {
      display: inline-block;
      padding: 50px 0 20px;
      @include font-styles(22px, 30px, 600, $c-dark);

      @include mobile {
        padding: 20px 0;
        @include font-styles(18px, 22px, 700, $c-dark);
      }
    }
    &-list {
      justify-content: space-between;
      @include flex-row();

      @include mobile {
        gap: 30px;
        grid-template-columns: repeat(7, calc((100vw - 30px) / 2.5));
        justify-content: flex-start;
        overflow-x: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }
}

.close-anim {
  animation: close ease-in-out 0.5s;

  @keyframes close {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.close-anim + .main-inner--open-modal::after {
  animation: close ease-in-out 0.5s;

  @keyframes close {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
</style>
