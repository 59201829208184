<template>
  <div
    class="modal"
    v-if="placeData"
    @click.self="$emit('input')"
    v-click-outside="onClickOutside"
  >
    <div class="modal__body">
      <div class="modal__close" @click="onClickOutside">
        <Icons icon-name="x" />
      </div>
      <div class="modal-content">
        <img
          :src="require(`@/assets/images/second/big-${placeData.bigImg}`)"
          :alt="`фото ${placeData.title}`"
          class="modal-content__img"
        />
        <div class="modal-content-wrapper">
          <span class="modal-content__title">{{ placeData.title }}</span>
          <span class="modal-content__time"
            ><Icons icon-name="car" /> {{ placeData.time }}</span
          >
          <p class="modal-content__subtitle">{{ placeData.subtitle }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icons from '@/components/atoms/Icons/index.vue'
import vClickOutside from 'v-click-outside'

export default {
  components: { Icons },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    placeData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onClickOutside() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 10%;
  left: 50%;
  width: 450px;
  min-height: 550px;
  z-index: 1050;
  // margin-top: -300px;
  margin-left: -225px;
  animation: appearance ease-in-out 1s;

  @include mobile {
    left: 15px;
    width: calc(100vw - 30px);
    margin: 0;
    top: 15px;
  }

  @keyframes appearance {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &__body {
    overflow: hidden;
    border-radius: 40px;
    position: relative;
    width: 100%;
    height: 100%;
    background: $white;

    @include mobile {
      margin: 0 auto;
      width: 100%;
    }
  }
  &__close {
    position: absolute;
    top: 30px;
    right: 40px;
    width: 36px;
    height: 36px;
    background: $gray-700;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 200;
  }
  &-content {
    &__img {
      width: 100%;
      max-height: 272px;
      background-size: cover;

      @media (max-width: 380px) {
        height: 150px;
      }
    }
    &-wrapper {
      padding: 26px;

      @include mobile {
        padding: 0 20px 20px;
      }
    }
    &__title {
      display: inline-block;
      padding: 5px 0;
      @include font-styles(26px, 36px, 600, $c-dark);
    }
    &__time {
      gap: 7px;
      padding-bottom: 22px;
      align-items: center;
      @include flex-row();

      @include font-styles(15px, 24px, 400, $c-gray, 0, $font-second);

      @include mobile {
        padding-bottom: 12px;
      }
      @include mobile-small {
        padding-bottom: 7px;
      }
    }
    &__subtitle {
      @include font-styles(14px, 26px, 400, $c-gray, 0, $font-second);
    }
  }
}
</style>
